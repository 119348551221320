<template>
  <div class="px-6 py-4">
    <div class="d-flex justify-end">
      <!-- Sort -->
      <v-chip
        v-if="!isEmpty"
        class="ma-2"
        color="white"
        text-color="secundary"
        @click="comments.reverse()"
      >
        <v-icon class="mr-1" v-text="'sort_by_alpha'" />
        {{ $t('common.latest') }}
      </v-chip>
      <!-- Search -->
      <span
        v-if="!isEmpty"
      >
        <v-text-field
          v-model="search"
          :label="$t('common.searchByContent')"
          prepend-inner-icon="search"
          tag="span"
          outlined
          dense
          hide-details
        />
      </span>
    </div>

    <v-list v-if="!isEmpty" three-line class="px-0 py-6">
      <comment-list-item v-for="item in treeComments" :key="item.id" :item="item" />
    </v-list>
    <empty-list v-else :content="$t('common.comments')" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CommentList',
  components: {
    CommentListItem: () => import('@/modules/activity/CommentListItem'),
    EmptyList: () => import('@/components/EmptyList'),
  },
  data() {
    return {
      search: '',
      sortDesc: true,
    }
  },
  computed: {
    ...mapGetters('activity', ['comments']),
    isEmpty({ comments }) {
      return !comments?.length
    },
    treeComments() {
      const getCommentAndChildren = comment => ({
        ...comment,
        children: this.comments.filter(c => c.parentId === comment.id).map(child => getCommentAndChildren(child)),
      })
      return this.comments?.filter(c => !c.parentId && c.content.includes(this.search))?.map(comment => getCommentAndChildren(comment))
    },
  },
}
</script>
